import { graphql } from 'gatsby';
import React from 'react'
import Layout from '../components/layout';
import ReadingBlogPost from '../components/ReadingBlogPost';
import Seo from '../components/seo';
import TagFilter from '../components/TagFilter';

export default function TagPage ({ data, pageContext }) {
   const readings = data.readings.nodes;
   const tags = pageContext.tags;

  //  const readings = data.readings.nodes;
   return (
    <Layout>
      <Seo title={pageContext.tag}/>
        <div className="container mainBlogPage">
        <div className="row">
            <div className="col">
            <h2>{`${pageContext.tag}`}</h2>
            </div>
            <TagFilter tagsArray={tags} />
        </div>
        <div className="row">
            {readings.map(reading => (<ReadingBlogPost key={reading.frontmatter.slug} reading={reading} />))}
        </div>
        </div>
  </Layout>
   )
}

export const query = graphql`
query ($tag: String!) {
  readings: allMarkdownRemark(filter: {frontmatter: {post_tags: {eq: $tag}}}) {
    nodes {
      frontmatter {
        post_headline
        post_subtitle
        post_tags
        feat_image_toggle
        featured_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        slug
      }
      excerpt(pruneLength: 70)
    }
  }
}
`