import React, {useState} from 'react';
import { Link } from 'gatsby';

export default function TagFilter({ tagsArray }) {
    const [filter, setFilter] = useState(false)
    // const tagReadings = readings;

    const toggleFilter = () => {
        setFilter(!filter)
    }

        // const tagSet = [...new Set(tags)]
    return (
        <div>
             <div className="dropdown tagFilter">
                <button className="btn dropdown-toggle" id="tagFilterBtn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={filter ? "true" : "false"} onClick={()=> toggleFilter()}>
                    Filter by Tag
                </button>
                <div className={filter ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="tagFilterBtn">
                    
                    <div className="dropdown-item">
                        <Link to="/further-reading">All</Link>    
                    </div>
                    {tagsArray.map(tag =>
                    <div className="dropdown-item" key={tag}> 
                        <Link to={`/further-reading/${tag}`}>{`${tag}`}</Link>
                    </div>
                    )}
                </div>
            </div> 
        </div>
    )
}