import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const BlogPost = ({reading}) => {
const hasImage = reading.frontmatter.feat_image_toggle;    
const image = (
    hasImage ?
<GatsbyImage image={getImage(reading?.frontmatter?.featured_image)} alt={reading.frontmatter.post_headline} />
    : null
)



    return (
        <div className="col-12 col-md-6">
        <Link to={`/${reading.frontmatter.slug}`}> 
        <div className={hasImage ? 'blogCard' : 'blogCard no-img'}>
            {hasImage === true ? image : null}
            <h4>{reading.frontmatter.post_headline}</h4>
            <p>{reading.excerpt}</p>
        </div>
        </Link>
    </div>
    )
}

export default BlogPost